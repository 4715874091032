/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Debabrata Banerjee",
  title: "Hi all, I'm Debabrata",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / React Native and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1ZTn3trrblvLerVGdq6lXla_JGJx4HiQj/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/debabratabanerjee",
  linkedin: "https://www.linkedin.com/in/debabrata-banerjee-0748b3180/",
  gmail: "debabratabanerjee023@gmail.com",
  //gitlab: "https://gitlab.com/saadpasta",
  facebook: "https://www.facebook.com/debabrata.banerjee.5055233/",
  medium: "https://debabrataban.medium.com/",
  //stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  instagram:"https://www.instagram.com/debabrata_i/",
  twitter:"https://twitter.com/DebabrataBaner8",
  whatsapp: "https://api.whatsapp.com/send?phone=917477357369&amp;text=Hi there! I Like Your Website :)",
  // Instagram and Twitter are also supported in the links!
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Engineering and Management",
      logo: require("./assets/images/UEM_logo.webp"),
      subHeader: "Bacherlor of Technology in Computer Science",
      duration: "May 2018 - April 2022",
      desc: "Back bone to my technological interests and education",
      descBullets: [
        "Participated in Hackathon",
        "Contributed in Open-source projects"
      ]
    },
    {
      schoolName: "Guru Teg Public School",
      logo: require("./assets/images/guruteg.jpg"),
      subHeader: "Higher Secondary",
      duration: "March 2016 - April 2018",
      //desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      //descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design ", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming (Data Structure and Algo)",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Founder and CEO",
      company: "SlideWay",
      companylogo: require("./assets/images/Slied Way logo.png"),
      date: "June 2020 – Present",
      desc: "Slideway is a platform for IT services, consulting & business solutions with innovation & delivery centers. We are alwaus devloping Ourselves",
      descBullets: [
        "Development(Web, Android)",
        "Desgin(static and motion)"
      ],
      url: "https://slideway.dev/",
      
    },
    {
      role: "Web Developer/Graphic Desginer",
      company: "Kita",
      companylogo: require("./assets/images/Flamingo Logo.png"),
      date: "May 2020 - Ongoing",
      desc: "KITA - School Of Foreign Language is Kolkata's first comprehensive foreign language learning institute for children aged 3-18 to strengthen their reading, writing, vocabulary and conversational skills",
      descBullets: [
        "Free Library",
        "Book Store",
        "E-learning"
      ],
      url: "https://kitaofficial.com/"
    },
    {
      role: "Web Developer",
      company: "Diflin Tech",
      companylogo: require("./assets/images/diflin.jfif"),
      date: "June 2020 – Present",
      desc: "Diflin is an online based Software Company which provides best, unique and affordable Digital Services for it's clients.",
      descBullets: [
        
      ],
      url: "https://diflin.in/"
    },
    
    
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/writtendesk.png"),
      projectName: "Writtendesk",
      projectDesc: "A simple featured blogging app to get connected with others",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://writtendesk.slideway.dev"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/Flamingo Logo.png"),
      projectName: "School of Foreign languages | KITA",
      projectDesc: "KITA - School Of Foreign Language is Kolkata's first comprehensive foreign language learning institute for children aged 3-18 to strengthen their reading, writing, vocabulary and conversational skills",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://kitaofficial.com"
        }
        //  you can add extra buttons here.
      ]
    },
    
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      //url: "#",
      title: "Blog On a topic coming Soon",
      description:
        "Coming Soon"
    },
    
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/debabrata-banerjee2/embed/episodes/Trial-OF-Anchor-Fm-e12u9pv" 
  ,"https://anchor.fm/debabrata-banerjee2/embed/episodes/Trial-OF-Anchor-Fm-e12u9pv",
  "https://anchor.fm/debabrata-banerjee2/embed/episodes/Trial-OF-Anchor-Fm-e12u9pv",
  "https://anchor.fm/debabrata-banerjee2/embed/episodes/Trial-OF-Anchor-Fm-e12u9pv"],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all..",
  number: "6295852737",
  email_address: "debabratabanerjee023@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "debabratabaner8", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
